<template>
  <div class="community-page info_page">
    <header class="text_only">
      <h1>Explore Your Roots in Community</h1>
      <p>
        Journey with a supportive family of detectives across the Chinese diaspora. The My China Roots community on
        Discord is free to join and welcome to all!
      </p>
      <div class="header_actions">
        <mcr-button
          class="fat"
          :href="discordLink"
          target="_blank"
          @click.native="trackDiscordClick('community-page-top')"
          >Join Our Community</mcr-button
        >
      </div>
    </header>

    <div class="readable_content">
      <lazy-youtube-video :src="videoSrc" class="main-video"></lazy-youtube-video>
      <div class="image_panel reverse_panel">
        <div class="image_panel_text">
          <h4>It takes a village to find your village.</h4>
          <div class="text-block">
            <div>
              Tap into the collective wisdom of our intergenerational community. Bridging over 50 countries and 30
              ancestral regions in China, we love sharing stories and skills to support each other's journeys.
            </div>
          </div>
        </div>
        <div class="image_panel_image">
          <img
            :data-src="$getAsset('/assets/community/family-storybook.jpg')"
            class="lazyload"
            width="400"
            height="310"
          />
        </div>
      </div>

      <div class="image_panel">
        <div class="image_panel_text">
          <h4>Real-time discoveries and research help.</h4>
          <div class="text-block">
            <div>
              New to genealogy? Can't read Chinese? Get live support from ancestral detectives and translators
              experienced in decoding names, records, and more mysteries in your family tree.
            </div>
          </div>
        </div>
        <div class="image_panel_image">
          <img
            :data-src="$getAsset('/assets/community/research-lab-village.jpg')"
            class="lazyload"
            width="400"
            height="310"
          />
        </div>
      </div>

      <div class="image_panel reverse_panel">
        <div class="image_panel_text">
          <h4>Behind-the-scenes inspiration on your roots journey.</h4>
          <div class="text-block">
            <div>
              Learn from the ones who've lived it. Enjoy educational workshops, film screenings, and intimate Q&As with
              world-class storytellers... including elders in our own families!
            </div>
          </div>
        </div>
        <div class="image_panel_image">
          <img
            :data-src="$getAsset('/assets/community/Memoir-Lane-Norman-Chin.jpg')"
            class="lazyload"
            width="400"
            height="310"
          />
        </div>
      </div>

      <div class="image_panel">
        <div class="image_panel_text">
          <h4>Relaxed space to explore "Chinese-ness" on your own terms.</h4>
          <div class="text-block">
            <div>
              Express yourself through ancestral emojis and conversation channels dedicated to comfort foods, passion
              projects, heritage groups, gaming, even memes. All expressions of your curiosity are welcome!
            </div>
          </div>
        </div>
        <div class="image_panel_image">
          <img :data-src="$getAsset('/assets/community/noodlebowljpg.jpg')" class="lazyload" width="400" height="310" />
        </div>
      </div>

      <hr />
      <person-quotes-list :data="testimonials"></person-quotes-list>
    </div>

    <end-actions>
      <h5>Uncover your family stories, together.</h5>
      <mcr-button
        class="fat"
        :href="discordLink"
        target="_blank"
        @click.native="trackDiscordClick('community-page-end')"
        >Join Us on Discord</mcr-button
      >
    </end-actions>
  </div>
</template>

<script>
import EndActions from '@common/elements/buttons/endActions';
import mcrButton from '@common/elements/buttons/mcrButton';
import AnalyticsAmplitudeHandler from '@common/utils/analytics/analytics.amplitude';
import {getRoutePageName} from '@common/utils/analytics/utils.analytics';
import {getAsset} from '@common/utils/utils';
import LazyYoutubeVideo from 'vue-lazy-youtube-video';

import PersonQuotesList from '@/base/elements/personQuotesList';

export default {
  metaInfo: {
    title: 'My China Roots – Join Our Community on Discord',
    titleTemplate: null,
    meta: [
      {vmid: 'og:title', property: 'og:title', content: 'My China Roots – Join Our Community on Discord'},
      {
        vmid: 'description',
        name: 'description',
        content:
          'Journey with a supportive family of detectives across the Chinese diaspora. The My China Roots community on Discord is free to join and welcome to all!',
      },
      {
        vmid: 'og:image:secure_url',
        property: 'og:image:secure_url',
        content: getAsset('/assets/community/MCR_Discord_Server_Banner_Logo.png'),
      },
     {
        vmid: 'og:image',
        property: 'og:image',
        content: getAsset('/assets/community/MCR_Discord_Server_Banner_Logo.png'),
      },
    ],
  },
  computed: {
    videoSrc() {
      return 'https://www.youtube.com/embed/qch0y1mWzl0';
    },
    testimonials() {
      return [
        {
          quote:
            "This is the community I've been waiting for. Before, I felt so isolated and stuck in my research. Hitting my head on the wall trying to find stories that resonate with mine, as well as resources beyond European ancestry. My China Roots is a light at the end of that tunnel – the people who finally GET IT!!",
          imgSource: getAsset('/assets/community/discord/discord-avatar-kat.png'),
          name: 'Kathryn',
        },
        {
          quote:
            'This Discord server has surprised me beyond my expectations. When I first joined, I didn’t even know my village name. Now, thanks to everyone’s help here, I’ve discovered my generation poem and uncovered the names of my ancestors, even inaccurate ones!',
          imgSource: getAsset('/assets/community/discord/discord-avatar-andy.png'),
          name: 'Andy',
        },
        {
          quote:
            "Don't give up! After getting no response from relatives and clan associations, I'd resigned to fate that I wouldn't find anything. However, everyone's support and successes have given me hope and direction. Now I know that with enough persistence and helping hands, I can uncover new paths in my search.",
          imgSource: getAsset('/assets/community/discord/discord-avatar-jm.png'),
          name: 'J. Moy',
        },
        {
          quote:
            "I originally came for help finding my grandparents' villages. Now, not only have I grown closer with my family, I've found friends who've touched my heart in ways I didn't even know I needed support in. I've become a better person, and I now have a better understanding of who I am. It's been such an honor and joy to pay it forward with the skills and knowledge I've gained to help others discover their roots too.",
          imgSource: getAsset('/assets/community/discord/discord-avatar-tw.png'),
          name: 'T.W.',
        },
        {
          quote:
            'My China Roots has genuinely grown to feel like a second home to me. A family that radiates welcoming, wholesome, positive energy. Not only are the people knowledgable about roots research, they support me in pondering questions of my identity and what it means to belong in the Chinese diaspora family.',
          imgSource: getAsset('/assets/community/discord/discord-avatar-dc.png'),
          name: 'D.C.',
        },
        {
          quote:
            'As someone who has been deeply passionate about Chinese genealogy for years, I am really grateful to have found this community! I can finally share my excitement with friends who can fully appreciate it, and likewise be part of their journey in tracing their roots.',
          imgSource: getAsset('/assets/community/discord/discord-avatar-xj.png'),
          name: 'X.J.',
        },
        {
          quote:
            'I enjoy the connection I have with other people here. It’s fun to hear the stories of our families and share a variety of Chinese food and opera too!',
          imgSource: getAsset('/assets/community/discord/discord-avatar-cai.png'),
          name: 'Cailin',
        },
        {
          quote:
            "The MCR community is a true gem of the Internet. Full of curious and caring souls, it's a warm, safe space for me to ask existential questions AND have fun. A magical place for both thought-provoking and playful conversations, there's something for everyone to join in and celebrate our stories.",
          imgSource: getAsset('/assets/community/discord/discord-avatar-ang.png'),
          name: 'Angela',
        },
        {
          quote:
            "As independent as I’d like to think I am in my genealogy research, everyone needs a community to celebrate their breakthroughs. My China Roots is that community. They abound with positive, calming energy from diverse people around the world. Even for peripheral topics like history, geography, and language, MCR will welcome you with open arms. Not to mention, their events offer cool opportunities you won't want to miss!",
          imgSource: getAsset('/assets/community/discord/discord-avatar-q.png'),
          name: 'Q.',
        },
        {
          quote:
            "Starting out, I felt the opportunity was in my hands to preserve my family history for future generations. If I didn't start asking questions, nobody would. All our stories would slowly fade away. Now, having found and visited our villages in China, I understand who I am and the path my family has taken to where we are today. I am lucky to have this community where I can help others find their village on a map, analyze their zupu, and progress in our research together!",
          imgSource: getAsset('/assets/community/discord/discord-avatar-jer.png'),
          name: 'Jeremy',
        },
      ];
    },
    discordLink() {
      return process.env.VUE_APP_DISCORD_INVITE_LINK_COMMUNITY;
    },
  },
  methods: {
    trackDiscordClick(linkLocation) {
      AnalyticsAmplitudeHandler.trackClickDiscordLink(linkLocation, getRoutePageName(this.$route));
    },
  },
  name: 'CommunityPage',
  components: {EndActions, PersonQuotesList, mcrButton, LazyYoutubeVideo},
};
</script>

<style lang="scss" scoped>
.community-page {
  .header_actions {
    margin-top: 30px;
  }

  .main-video {
    margin-bottom: 30px;
  }

  .image_panel {
    padding-left: 0;
    padding-right: 0;
    @media only screen and (min-width: $breakpoint-tablet) {
      .image_panel_image {
        img {
          width: 400px;
          height: 300px;
          object-fit: cover;
        }
      }
    }
  }

  .person-quotes-list {
    margin-top: 50px;
    grid-template-columns: repeat(2, 1fr);

    &::v-deep .person-quote .client img {
      height: 50px;
    }

    @media only screen and (max-width: $breakpoint-phablet) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .end_actions {
    .mcr-button {
      margin-top: 30px;
    }
  }
}
</style>
